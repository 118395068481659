import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Helmet } from "react-helmet";
import Layout from "@components/layout";
import PostsIntro from "@modules/posts-intro";
import InsightsPost from "@modules/insights-posts";
import GetStarted from "@modules/get-started";

const Reports = () => {
  const {
    markdownRemark: {
      frontmatter: { intro, featuredPost, getInTouchBlockData, pageName },
    },
  } = useStaticQuery(
    graphql`
      {
        markdownRemark(fileAbsolutePath: { regex: "/reports-data/" }) {
          frontmatter {
            intro {
              backgroundColor
              smallHeader
              largeHeader
              description
              buttonLabel
              subscriptionLabel
              subscriptionInputPlaceholder
              sendIcon {
                publicURL
                childImageSharp {
                  fixed(width: 20) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
              thanksMessage
            }
            featuredPost {
              slug
              header
              category
              title
              image {
                publicURL
                childImageSharp {
                  fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            getInTouchBlockData {
              backgroundColor
              blockBackground
              message
              buttonLabel
            }
            pageName
          }
        }
      }
    `
  );

  return (
    <Layout>
      <Helmet>
        <title>{pageName}</title>
      </Helmet>
      <PostsIntro {...intro} formName="Reports Subscription Form" />
      <InsightsPost
        postType="reports"
        linkTemplate="/reports/"
        featuredPost={featuredPost}
      />
      <GetStarted
        backgroundColor={getInTouchBlockData.backgroundColor}
        blockBackground={getInTouchBlockData.blockBackground}
        message={getInTouchBlockData.message}
        buttonLabel={getInTouchBlockData.buttonLabel}
      />
    </Layout>
  );
};

export default Reports;
